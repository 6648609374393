<template>
  <div>
    <div class="tool-container flexible">
      <div class="tool-content">
        <div class="page-title clearfix">
          <div class="float-left">
            <span>{{ $t("nav.tool_download") }}</span>
            <span class="total-num">3</span>
          </div>
        </div>
        <div class="main-content row">
          <div class="col-3 rounded animated fadeInLeft">
            <div class="tool-card mb-4 shadow clearfix">
              <div class="card-left fl"></div>
              <div class="card-right fl">
                <p class="tool-name">加密工具</p>
                <p class="system">Windows,Mac系统</p>
              </div>
            </div>
          </div>
          <div class="col-3 rounded animated fadeInLeft">
            <div class="tool-card mb-4 shadow clearfix">
              <div class="card-left fl"></div>
              <div class="card-right fl">
                <p class="tool-name">加密工具</p>
                <p class="system">Windows,Mac系统</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projectList: new Array(),
      shareProjectList: new Array(),
      showSearchDrawer: false,
      currentPage: 2,
      showModel: false,
      modelType: "",
      project: {},
      id: "",
      userLevel: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).level
        : ""
    };
  }
};
</script>

<style lang="less">
.tool-content {
  width: 100%;
  .main-content {
    padding-top: 20px;
  }
  .tool-card {
    padding: 30px;
    height: 140px;
  }
  .card-left {
    margin-right: 30px;
    width: 80px;
    height: 80px;
    background: #e5e5e5;
    border-radius: 5px;
  }
  .card-right {
    padding-top: 15px;
    .tool-name {
      margin-bottom: 10px;
      height: 24px;
      line-height: 24px;
      font-size: 24px;
      color: #212528;
    }
    .system {
      color: #6c757d;
    }
  }
}
</style>
